import { successSnackbar } from '@/helpers/snackbar'
import { mapGetters } from 'vuex'
import store from '@/store'

const rowDeleteActionsMixins = {
    computed: {
        ...mapGetters({ hasRole: 'auth/hasRole' }),
        canDelete() {
            return this.hasRole(this.params.deleteButton?.roles)
        },
        deleteTitle() {
            return this.params.deleteButton?.title || 'Item'
        },
        deleteActionParams() {
            const params = this.params.deleteButton?.actionParams || []

            return params.reduce((acc, curr) => {
                acc[curr] = this.params.data[curr]

                return acc
            }, {})
        },
        deleteItemIdentifier() {
            return this.params.data[this.params.deleteButton.itemIdentifier] || null
        },
        deleteItemType() {
            return this.params.deleteButton.itemType || 'item'
        },
        deleteText() {
            const idText = this.deleteItemIdentifier ? `"${this.deleteItemIdentifier}"` : 'this'
            const typeText = this.deleteItemType

            return `Are you sure you want to delete ${idText} ${typeText}?`
        },
    },
    methods: {
        async deleteItem() {
            if (!this.deleteActionParams) {
                return
            }

            const { namespace, vuexMethod } = this.params.deleteButton
            await store.dispatch(`${namespace}/${vuexMethod}`, this.deleteActionParams)
            await this.params.api.refreshServerSideStore({ purge: false })

            await successSnackbar({ message: `${this.deleteTitle} deleted` })
        },
    },
}

export default rowDeleteActionsMixins
