<template>
    <v-chip class="ma-2" :color="color">{{ text }}</v-chip>
</template>

<script>
export default {
    name: 'ChipBoolean',
    computed: {
        color: function () {
            let { value, inverted } = this.params
            value = inverted ? !value : value
            return value ? 'green' : 'red'
        },
        text: function () {
            let { value, invertedValue } = this.params
            value = invertedValue ? !value : value
            return value ? 'Yes' : 'No'
        },
    },
}
</script>
